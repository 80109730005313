.container {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.heading {
    font-size: medium;
    margin-bottom: 8px;
    /* font-weight: 400; */
}

.text-center {
    text-align: center;
}


.grey {
    background-color: lightgrey;
}

.shape-panel-btn{
    float: right; 
    width: 10%; 
    margin: 10px !important; /* To oversrite css of 'rs-btn' from React suite */
    width: auto;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type="file"] {
  display: none;
}

ul {
    margin-top: 5px;
}
ul.dashed {
    list-style-type: none;
    margin-top: 5px;
}
ul.dashed > li {
    text-indent: -5px;
    margin-top: 2px;
}
ul.dashed > li:before {
    content: "- ";
    text-indent: -5px;
}

.custom-file-upload {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  text-align: center;

  border-radius: 3px;
  border: 1px solid rgb(54, 124, 255);
  transition: border 0.4s ease-out;
  color: rgb(54, 124, 255);
  background: rgba(255, 255, 255, 0.05);
}

.root-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.box-controller {
    visibility: visible;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 24em;
    min-width: 21em;
    height: 35px;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(15, 15, 15, 0.2);
    box-shadow: 0px 0px 20px 0px rgba(15, 15, 15, 0.2);
    margin-bottom: 30px;
    align-items: center;
    transition: visibility 0.5s ease-out;
}

.controller {
    flex: 1;
    text-align: center;
    height: 100%;
    line-height: 2;
    cursor: pointer;
}

.selected-controller {
    transition: border 0.5s ease-out;
    border-bottom: 2px solid #70a1ff;
}

.center-box {
  margin-top: 100px;
  width: 100%;
  display: flex;
  flex-direction: both;
  justify-content: center;

}

.box-container {
    display: flex;
    flex-direction: column;
    max-width: 33em;
    min-width: 30em;
    box-shadow: 0px 0px 20px 0px rgba(15, 15, 15, 0.2);
    border-radius: 6px;
    padding: 24px;
}

.inner-container {
    transition: visibility 0.2s ease-out;
}

.inner-container.show {
    visibility: visible;
}

.header {
    text-align: center;
    padding: 5px;
    margin-bottom: 17px;
    /* margin-left: 35px; */
    /* margin-right: 35px; */
    font-family: Ozxgen, sans-serif;
    font-size: 22px;
    border-bottom: 2px solid #70a1ff;
}

.box {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.input-group {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    margin-bottom: 5px;
}

.login-label {
    font-family: Oxygen, sans-serif;
    font-size: 20px;
    font-weight: 600;
    padding-left: 5px;
}

.login-input {
    height: 2.1em;
    border-radius: 3px;
    border: 1px solid #70a1ff;
    transition: border 0.4s ease-out;
    padding: 13px;
    font-family: Oxygen, sans-serif;
    font-size: 19px;
    color: #3e3e42;
    background: rgba(15, 15, 15, 0.01);
}

.login-input:hover {
    border: 2px solid #70a1ff;
}

.login-input:focus {
    border: 2px solid #6565e7;
    box-shadow: 0px 0px 20px rgba(15, 15, 15, 0.2);
}

.login-input::placeholder {
    font-family: Oxygen, sans-serif;
    font-size: 16px;
    color: rgba(15, 15, 15, 0.4);
}

.login-btn {
    padding: 2px 30px;
    /* background-color: #417cef; */
    border: 0;
    font-size: 18px;
    border-radius: 3px;
    font-family: Oxygen, sans-serif;
    background-color: rgba(15, 15, 15, 0.03);
    margin-top: 20px;
    border: 2px solid #417cef;
    transition: background-color 0.3s ease-out;
    cursor: pointer;
}

.login-btn:hover,
.login-btn:focus {
    background-color: #417cef;
    color: white;
}

.danger-error {
    color: #e74c3c;
    font-size: 16px;
}

.password-state {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.pwd {
    height: 6px;
    flex: 1;
    visibility: hidden;
}

.pwd-weak {
    background-color: #e74c3c;
}

.pwd-medium {
    background-color: #3498db;
}

.pwd-strong {
    background-color: #2ecc71;
}

.show {
    visibility: visible;
}